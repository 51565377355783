import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Adding services to a server";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "adding-services-to-a-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#adding-services-to-a-server",
        "aria-label": "adding services to a server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding services to a server`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-you-need"
          }}>{`What you need`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#1-create-a-service-file"
          }}>{`1. Create a service file`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#2-add-a-blog-service-to-server"
          }}>{`2. Add a blog service to server`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#3-add-the-documentation-service"
          }}>{`3. Add the Documentation service`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#4-run-the-server-and-services"
          }}>{`4. Run the server and services`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#5-check-docservice-page"
          }}>{`5. Check DocService page`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-docservice-after-adding-service-methods"
            }}>{`Using DocService after adding service methods`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#whats-next"
          }}>{`What's next?`}</a></p>
      </li>
    </ul>
    <p>{`In this step, we'll add an empty blog service to the server we created in `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/create-server"
      }}>{`Step 1. Create a server`}</a>{`. Also, we'll add Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{` for testing our blog service.`}</p>
    <TutorialSteps current={3} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following file obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/main/java/example/armeria/server/blog/"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-create-a-service-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-create-a-service-file",
        "aria-label": "1 create a service file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Create a service file`}</h2>
    <p>{`Create a service file, `}<inlineCode parentName="p">{`BlogService.java`}</inlineCode>{`. You can see the full version of the file `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/main/java/example/armeria/server/blog/BlogService.java"
      }}>{`here`}</a>{`. We'll add on service methods in this class in later steps.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java",
        "filename": "BlogService.java"
      }}>{`package example.armeria.server.blog;

import java.util.Map;
import java.util.concurrent.ConcurrentHashMap;

public final class BlogService {
  private final Map<Integer, BlogPost> blogPosts = new ConcurrentHashMap<>();
}
`}</code></pre>
    <h2 {...{
      "id": "2-add-a-blog-service-to-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-add-a-blog-service-to-server",
        "aria-label": "2 add a blog service to server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Add a blog service to server`}</h2>
    <p>{`In `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/create-server"
      }}>{`Step 1. Create a server`}</a>{`, we added a dummy service just to check that a server is launched. Now, let's remove the dummy service and add an empty blog service, instead.`}</p>
    <ol>
      <li parentName="ol">{`From `}<inlineCode parentName="li">{`Main.java`}</inlineCode>{`, remove the dummy service at line 4.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=4 showlineno=true",
            "filename": "Main.java",
            "highlight": "4",
            "showlineno": "true"
          }}>{`static Server newServer(int port) {
  ...
  return sb.http(port)
           .service("/", (ctx, req) -> HttpResponse.of("Hello, Armeria!")) // Remove this
           .build();
`}</code></pre>
      </li>
      <li parentName="ol">{`Add our service by adding line 4.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=4 showlineno=true",
            "filename": "Main.java",
            "highlight": "4",
            "showlineno": "true"
          }}>{`static Server newServer(int port) {
  ...
  return sb.http(port)
           .annotatedService(new BlogService())   // Add this
           .build();
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "3-add-the-documentation-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-add-the-documentation-service",
        "aria-label": "3 add the documentation service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Add the Documentation service`}</h2>
    <p>{`This time, we'll add Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{` method, add a `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` and a request example for `}<a parentName="p" {...{
            "href": "/tutorials/rest/blog/implement-create"
          }}>{`creating blog posts`}</a>{`,
using `}<a parentName="p" {...{
            "href": "type://DocServiceBuilder#exampleRequests(Class,String,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html#exampleRequests(java.lang.Class,java.lang.String,java.lang.Iterable)"
          }}>{`type://DocServiceBuilder#exampleRequests(Class,String,Iterable)`}</a>{`. Feel free to add more examples for other service methods.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=1,6-13",
            "filename": "Main.java",
            "highlight": "1,6-13"
          }}>{`import com.linecorp.armeria.server.docs.DocService;

public final class Main {
  static Server newServer(int port) {
    ServerBuilder sb = Server.builder();
    DocService docService =
            DocService.builder()
                      .exampleRequests(BlogService.class,
                          "createBlogPost", // Name of service method
                          "{\\"title\\":\\"My first blog\\", \\"content\\":\\"Hello Armeria!\\"}")
                      .build();
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Inside the `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{` method, add the `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` to our server builder.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=5",
            "filename": "Main.java",
            "highlight": "5"
          }}>{`static Server newServer(int port) {
  ...
  return sb.http(port)
           .annotatedService(new BlogService())
           .serviceUnder("/docs", docService)  // Add Documentation service
           .build();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`(Optional) To access the Documentation service result easily, edit the log message we added in `}<a parentName="p" {...{
            "href": "/tutorials/rest/blog/create-server"
          }}>{`Step 1. Create a server`}</a>{` to the one specified below.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=2",
            "filename": "Main.java",
            "highlight": "2"
          }}>{`public static void main(String[] args) throws Exception {
    logger.info("Server has been started. Serving DocService at http://127.0.0.1:{}/docs",
                server.activeLocalPort());
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "4-run-the-server-and-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-run-the-server-and-services",
        "aria-label": "4 run the server and services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Run the server and services`}</h2>
    <p>{`Like we did in `}<em parentName="p">{`Step 1. Create a server`}</em>{`, `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/create-server#3-run-the-server-and-service"
      }}>{`build and run`}</a>{` the service by running the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` method or using Gradle.`}</p>
    <p>{`The server and services are launched successfully if you see this message.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{` Server has been started. Serving DocService at http://127.0.0.1:8080/docs
`}</code></pre>
    <h2 {...{
      "id": "5-check-docservice-page",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-check-docservice-page",
        "aria-label": "5 check docservice page permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Check DocService page`}</h2>
    <p>{`Let's test and call our service operations, using Armeria's Documentation service.`}</p>
    <p>{`Click the URL `}<a parentName="p" {...{
        "href": "http://127.0.0.1:8080/docs"
      }}>{`http://127.0.0.1:8080/docs`}</a>{` from the log message or open the URL on a web browser. If you see the Document service page, you've launched the `}<a parentName="p" {...{
        "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
      }}>{`type://DocService`}</a>{` and server successfully. We're seeing no services on the
page because we're to implement service methods.`}</p>
    <p>{`  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.355828220858896%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABC0lEQVQY03WP30vDMBDH8/eJ4F58EplWfRU2/zqnD9K9ibSjIMhotyH2R9a5urZpkkuaSJOtiuCH4/K9I3d8Dx2fTwaXD4OLLp8MJ0dn96c3j9d3rjNyndHUGU+N+BtXY3d4+4T8YOUHSz9YebPImy2evfDFj17f4nCxmUf5PMrD5ed/gTBO4o/3LI2zNMZZsl6nGCebHOd5VpVfnDdaS61kl63otZZotyuLoqiqCoTgnAPwAwCiA8wLBiGkEBIOICm7QmsNAFJKbVBKUUptX6mWMaoMlFJCiFLK/kEA0DSN1ppzXpalnW/blhBiNYDcbqldWte13fgz3BtjjMHebHeC2NsWjIH4dULPN4zVdg3kSCeXAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "tutorial blogservice docservice start",
          "title": "tutorial blogservice docservice start",
          "src": "/static/2a1766e9cfd0f3ae659db7b334c67873/a6d36/tutorial_blogservice_docservice_start.png",
          "srcSet": ["/static/2a1766e9cfd0f3ae659db7b334c67873/222b7/tutorial_blogservice_docservice_start.png 163w", "/static/2a1766e9cfd0f3ae659db7b334c67873/ff46a/tutorial_blogservice_docservice_start.png 325w", "/static/2a1766e9cfd0f3ae659db7b334c67873/a6d36/tutorial_blogservice_docservice_start.png 650w", "/static/2a1766e9cfd0f3ae659db7b334c67873/e548f/tutorial_blogservice_docservice_start.png 975w", "/static/2a1766e9cfd0f3ae659db7b334c67873/c23ad/tutorial_blogservice_docservice_start.png 1193w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "using-docservice-after-adding-service-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-docservice-after-adding-service-methods",
        "aria-label": "using docservice after adding service methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using DocService after adding service methods`}</h3>
    <p>{`When you add service methods through later steps, you'll see a result similar to this.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.355828220858896%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABP0lEQVQY0z2P207CQBCG+3hEYrzWECTcG+WZfAethgsFLyTUNh4AwVJEDlLapmu63e5pdk23CV/+/JnJzCT/WLUz+7h5d9S4rTfsesOundon5/ftTq/d6bc7T0b91lWvddkz/nhQ8+LBentfjcbrYImCZTr63A4c3/EWjhu8eAvHC4bu3H39nszC6VdUuh/N5nGlqR9Zf4xkjGgDpfnv9ifcrcPdZh9uov3vPtzG8S5JQoRiWmAFXGs4yBoO/PFoRUieZTjPCUIoTRGljHPOSuNCCFbCpZSmE0JIbrC84fXk4yZJEEJIKcUYAwDOOQBorQGAEAKGoiiUUkVR5HmuDFbX7Q7Gz0mcpGlarUopGWNZlimlpJQYY/ORTNNCa40x5pxrrctjhkBkoLUCgCphFYlSKoQw4VlZMEEpPyxUo3+jrXDABNiz6QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "tutorial blogservice docservice end",
          "title": "tutorial blogservice docservice end",
          "src": "/static/d6d50e14da73d95b6982aa75b8e75849/a6d36/tutorial_blogservice_docservice_end.png",
          "srcSet": ["/static/d6d50e14da73d95b6982aa75b8e75849/222b7/tutorial_blogservice_docservice_end.png 163w", "/static/d6d50e14da73d95b6982aa75b8e75849/ff46a/tutorial_blogservice_docservice_end.png 325w", "/static/d6d50e14da73d95b6982aa75b8e75849/a6d36/tutorial_blogservice_docservice_end.png 650w", "/static/d6d50e14da73d95b6982aa75b8e75849/e548f/tutorial_blogservice_docservice_end.png 975w", "/static/d6d50e14da73d95b6982aa75b8e75849/d326d/tutorial_blogservice_docservice_end.png 1197w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`To test your service methods with the `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`createBlogPost()`}</strong>{` method link in the left panel. You can make calls to the creation method from the page opened.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "536px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.91411042944786%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKElEQVQ4y+1UPUsEMRDNjxXsBXvB9sDKH2CnnaXd+QfUWjxPsBCOc3eT3XxsNpk8ydyH7Hmertc64RGGmbzMMMkT5DvQXIGkBhUNqLZIeVFCSsMhkg/ATAHSAKUGdIuhlolWJkIIsL6Fdhau81BNjaZpUNc1tNaoqgo5xzkHKSX73nuUZck+Ea1JucKiKPA8meBlOsX7fM5J1lomNMbwwUzQti3HlFJ8QY5vEq4rzAe6rkOMEX+xXsvbgkPRq/CnhKEm9iX4J9w+lH0giP8sehP++tCW+E2Fn9nEe0cRYROJFoiBf8kuiJvxK05Gdzg9e8DF5SNgHGKlWXVYgZRdqFBWJB+WSkTft3x1/YSDozEOj28xOr8HrGOCJA3Sap9JpLcSrEzY3f4HDGHpc8OkSYUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice reqex",
              "title": "tutorial blogservice reqex",
              "src": "/static/fe7bc50893d685163e6ab61bd07646ea/2d920/tutorial_blogservice_reqex.png",
              "srcSet": ["/static/fe7bc50893d685163e6ab61bd07646ea/222b7/tutorial_blogservice_reqex.png 163w", "/static/fe7bc50893d685163e6ab61bd07646ea/ff46a/tutorial_blogservice_reqex.png 325w", "/static/fe7bc50893d685163e6ab61bd07646ea/2d920/tutorial_blogservice_reqex.png 536w"],
              "sizes": "(max-width: 536px) 100vw, 536px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p parentName="li">{`Note that in the `}<strong parentName="p">{`REQUEST BODY`}</strong>{` section that the values specified for the `}<a parentName="p" {...{
            "href": "type://DocServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html"
          }}>{`type://DocServiceBuilder`}</a>{` are automatically entered on the page.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=5-8",
            "filename": "Main.java",
            "highlight": "5-8"
          }}>{`static Server newServer(int port) {
  ServerBuilder sb = Server.builder();
  DocService docService =
    DocService.builder()
              .exampleRequests(
                BlogService.class,
                "createBlogPost",
                "{\\"title\\":\\"My first blog\\",\\"content\\":\\"Hello Armeria!\\"}")
              .build();
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`SUBMIT`}</strong>{` button, and you'll see the blog post information returned in the right panel.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "23.31288343558282%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnklEQVQY04WP2wqDMBBE/f9fLPTJG6Jp1GYv2SRTtEVaSuvCYR8W5uxUFhVnE6MihAAzQynlL1U9BvSe0Xva93ATDE4wTE/cmkCiYCLknE/l1bVeUY+MboloZ0XrFO2kaCbZ6XyEpgIOCSyGQLyTUtoDvj7EykC0U7MfBX4TuzsuzQy3yEfg8SG9qmzGX2x3IYNSgljBwhkc8xH4XvkB+c+IMOE++n4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice return",
              "title": "tutorial blogservice return",
              "src": "/static/3a76751dde120ef62c03b5b8964be507/a6d36/tutorial_blogservice_return.png",
              "srcSet": ["/static/3a76751dde120ef62c03b5b8964be507/222b7/tutorial_blogservice_return.png 163w", "/static/3a76751dde120ef62c03b5b8964be507/ff46a/tutorial_blogservice_return.png 325w", "/static/3a76751dde120ef62c03b5b8964be507/a6d36/tutorial_blogservice_return.png 650w", "/static/3a76751dde120ef62c03b5b8964be507/e548f/tutorial_blogservice_return.png 975w", "/static/3a76751dde120ef62c03b5b8964be507/302a4/tutorial_blogservice_return.png 1080w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next?`}</h2>
    <p>{`In this step, we've added an empty blog service and the Documentation service to a server.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/implement-create"
      }}>{`Step 4. Implement CREATE`}</a>{`, we'll finally implement a CREATE
operation to create blog posts.`}</p>
    <TutorialSteps current={3} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      